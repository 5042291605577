import {List} from '@material-ui/core';
import {
    Home as HomeIcon, AttachMoney as AttachMoneyIcon, Feedback as FeedbackIcon, ListAlt as ListAltIcon, LocationCity as LocationCityIcon,
    MonetizationOn as MonetizationOnIcon, Person as PersonIcon, Settings as SettingsIcon, Star as StarIcon,
    TrendingUp as TrendingUpIcon, Toc as TocIcon
} from '@material-ui/icons';
import {useGetUserQuery} from 'generated/graphql';
import React, {FC} from 'react';

import AppMenuLink from './app-menu-link';

interface AppMenuProps {
    onClose?(): void;
}

const AppMenu: FC<AppMenuProps> = ({ onClose }) => {
    const { data } = useGetUserQuery();
    const user = data?.user;

    return (
        <>
            <List component='nav' onClick={onClose}>
                <AppMenuLink href='/home' label='Inicio' icon={<HomeIcon />} activeIfExactMatch={true} />
                <AppMenuLink href='/opportunities' label='Oportunidades' icon={<StarIcon />} />       
                <AppMenuLink href='/quotes-tracing' label='Seguimiento'  icon={<ListAltIcon />} activeIfExactMatch={true}/>
                <AppMenuLink href='/accounts' label='Cuentas' icon={<LocationCityIcon />} />
                <AppMenuLink href='/contacts' label='Contactos' icon={<PersonIcon />} />
                <AppMenuLink href='/sales-targets' label='Objetivos de venta' icon={<MonetizationOnIcon />} activeIfExactMatch={true} />
                {user?.permissions?.viewAnalytics && <AppMenuLink href='/analytics' label='Estadísticas' icon={<TrendingUpIcon />} />}
                {user?.isStaff && <AppMenuLink href='/admin' label='Administración' icon={<SettingsIcon />} isExternalLink={true} />}
                <AppMenuLink href='https://forms.gle/HztBuiizWmcP8JiB7' label='Dejanos tu opinión' icon={<FeedbackIcon />} />
            </List>
        </>
    );
};

export default AppMenu;
